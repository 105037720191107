import { graphql, Link, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { Col, Row } from "react-bootstrap";
import HardwareBase from "../../components/hardware-base";
import { DASHBOARD_URL, BRAND_NAME } from "../../util/constants";

const FireTVSetup = () => {
  const q = useStaticQuery(graphql`
    query {
      fireTv: file(relativePath: { eq: "help/firetv.png" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      installScreen: file(
        relativePath: { eq: "help/firetv-install-screen.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupPairing: file(relativePath: { eq: "help/setup-pairing.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }

      setupComplete: file(relativePath: { eq: "help/setup-complete.png" }) {
        childImageSharp {
          fluid(maxWidth: 900, quality: 95) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <HardwareBase
      platformName="Fire TV"
    >
      <Row>
        <Col>
          <p className="lead">
            It's extremely easy to turn a{" "}
            <a href="https://amzn.to/3hC4xqr" target="_new">
              Fire TV Device
            </a>{" "}
            into a digital beverage menu with {BRAND_NAME}. Follow these steps.
          </p>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 1: Get a Device</h3>
          <p>
            {`${BRAND_NAME} `} works best with the current generation of Amazon
            Fire TV devices.
          </p>
          <ul>
            <li>
              <b>
                <a href="https://amzn.to/3hC4xqr" target="_new">
                  Fire TV Stick
                </a>
              </b>
              : The best, lowest-cost option. Often available on sale for
              $20-$30.
            </li>
            <li>
              <b>
                <a href="https://amzn.to/3qyNSYM" target="_new">
                  Fire TV Stick 4K
                </a>
              </b>
              : An outstanding choice for high-quality displays.
            </li>
            <li>
              <b>
                <a href="https://amzn.to/3h6TvKt" target="_new">
                  Fire TV International Edition
                </a>
              </b>
              : For non-US customers, the International Edition is a great
              choice.
            </li>
          </ul>
          <p>
            Once you have one of these devices, simply install the{" "}
            <a href="http://amzn.to/1UjmjMI">{`${BRAND_NAME} `} App</a> and
            you're good to go! See our{" "}
            <Link to={`/help/fire-tv-setup`}>full Fire TV setup guide</Link>{' '}
            for detailed instructions.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.fireTv.childImageSharp.fluid}
            alt="An Amazon FireTV Stick"
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 2: Install the App</h3>
          <p>
            After activating your device, you'll need to download and install
            the {`${BRAND_NAME} `} app. In the Amazon app store, search for
            Taplist. You should see the {`${BRAND_NAME} `} App with our logo.
            Download it to your device.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.installScreen.childImageSharp.fluid}
            alt={`${BRAND_NAME} in the Amazon App Store`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Step 3: Pair Your Display</h3>
          <p>
            The first time the {`${BRAND_NAME} `} app launches, you will see a
            screen similar to the screenshot here. This pairing process securely
            links your new display to your {`${BRAND_NAME} `} account.
          </p>
          <p>
            Using your mobile phone, laptop, or any other device, visit the{" "}
            {`${BRAND_NAME} `} activation page and enter the code shown on
            screen.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupPairing.childImageSharp.fluid}
            alt={`The ${BRAND_NAME} pairing screen`}
          />
        </Col>
      </Row>
      <Row>
        <Col md={8}>
          <h3>Done!</h3>
          <p>
            You don't have to to anything else once your device has been
            successfully paired! You should now see your taps on screen.
          </p>
          <p>
            The Fire TV app is designed to be "set it and forget": You
            completely manage and control all aspects of your new display
            through the{"  "}
            <a href={DASHBOARD_URL}>{`${BRAND_NAME} `} Dashboard</a>, not the
            device itself.
          </p>
        </Col>
        <Col>
          <Img
            fluid={q.setupComplete.childImageSharp.fluid}
            alt={`The ${BRAND_NAME} menu display`}
          />
        </Col>
      </Row>
    </HardwareBase>
  );
};

export default FireTVSetup;
